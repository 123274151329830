import React from 'react'

import logo from '../assets/images/tbt-logo-white.svg'

const Header = props => (
  <header id="header" className="alt">
    {/* <span className="logo"><img src={logo} alt="" /></span> */}
    <div className="banner">
      <h1>Future Transit</h1>
      <h1 className="bg">&nbsp;</h1>
      <p>
        TBT is reviewing its current bus service to make the system easier,
        faster and more convenient for riders.
      </p>
    </div>
    <img className="logo-tbt" src={logo} />
  </header>
)

export default Header
